body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3fcf4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media print {
  ::-webkit-scrollbar {
    display: none;
  }
}

/* Stepper colour */
.MuiStepIcon-active {
  color: #164c3f !important;
}
.MuiStepIcon-completed {
  color: #2e8061 !important;
}
